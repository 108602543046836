import React, { createContext, useContext, useState, useEffect, useMemo } from 'react'
import { supabase } from '../supabase/supabaseClient'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchProfile = async (session) => {
      if (session?.user) {
        const { data: profile } = await supabase
          .rpc('get_profile_with_status', {
            user_id: session.user.id,
          })
          .single()
        setUser(profile)
      } else {
        setUser(null)
      }
      setLoading(false)
    }

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      fetchProfile(session)
    })

    return () => {
      authListener.subscription.unsubscribe()
    }
  }, [])

  const signIn = async (email, password) => {
    const { error } = await supabase.auth.signInWithPassword({ email, password })
    if (error) throw error
  }

  const signUp = async (email, password, firstName, lastName) => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          full_name: `${firstName} ${lastName}`,
        },
      },
    })
    if (error) throw error
    return data
  }

  const signOut = async () => {
    const { error } = await supabase.auth.signOut()
    if (error) throw error
  }

  const signUpWithFacebook = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'facebook',
    })
    if (error) throw error
  }

  const signUpWithGoogle = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
    })
    if (error) throw error
  }

  const checkIfUserExists = async (email) => {
    const { data, error } = await supabase.rpc('check_email_exists', { email_input: email })
    if (error) {
      return false
    }
    return !!data
  }

  const updateProfile = async (updates) => {
    const { data, error } = await supabase.from('profiles').update(updates).eq('id', user.id)
    if (error) throw error
    setUser({ ...user, ...updates })
    return data
  }

  const resetPassword = async (email) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-hasla`,
    })
    if (error) throw error
  }

  const updatePassword = async (newPassword) => {
    const { error } = await supabase.auth.updateUser({ password: newPassword })
    if (error) throw error
  }

  const value = useMemo(
    () => ({
      user,
      signIn,
      signOut,
      signUp,
      signUpWithFacebook,
      signUpWithGoogle,
      checkIfUserExists,
      updateProfile,
      resetPassword,
      updatePassword,
      loading,
    }),
    [user, loading]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth musi być używany wewnątrz AuthProvider')
  }
  return context
}
