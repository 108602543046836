import { Grid } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import CookiesBar from './CookiesBar'
import Footer from './Footer/Footer'
import MainNavbar from './Navbar/MainNavbar'
import SecondaryNavTabs from './Navbar/SecondaryNavTabs'
import { CONVERTER_ROUTES, HEATING_ROUTES, LAYOUTS, VENT_ROUTES, WATER_SEWAGE_ROUTES } from './Routes'
import { useAuth } from '../../contexts/authContext'
import { getThemeObject } from './theme'

export const DarkModeContext = createContext()
export const PRIMARY_MAIN = '#002C66'
const isBrowser = typeof window !== 'undefined'
const darkModeLocalSt = isBrowser ? window.localStorage.getItem('darkMode') : null

const Layout = ({ children, pageContext }) => {
  const [mode, setMode] = useState(darkModeLocalSt || 'light')
  const isDarkMode = mode === 'dark'
  const darkModeProviderValue = useMemo(() => ({ mode, setMode, isDarkMode }), [mode, setMode])
  const { user, loading } = useAuth()
  const [isClient, setIsClient] = useState(false)

  // fixes gatsby rehydration issue with style (darkmode)
  useEffect(() => {
    setIsClient(true)
  }, [])

  const isDark = mode === 'dark'
  const customTheme = useMemo(() => createTheme(getThemeObject(mode, isDark, PRIMARY_MAIN)), [mode])

  const renderNav = (layout) => {
    switch (layout) {
      case LAYOUTS.CONVERTERS:
        return <SecondaryNavTabs routes={CONVERTER_ROUTES}>{children}</SecondaryNavTabs>
      case LAYOUTS.HEATING:
        return <SecondaryNavTabs routes={HEATING_ROUTES}>{children}</SecondaryNavTabs>
      case LAYOUTS.WATER_SEWAGE:
        return <SecondaryNavTabs routes={WATER_SEWAGE_ROUTES}>{children}</SecondaryNavTabs>
      case LAYOUTS.VENT:
        return <SecondaryNavTabs routes={VENT_ROUTES}>{children}</SecondaryNavTabs>
      default:
        return children
    }
  }

  if (!isClient || loading) return null

  return (
    <DarkModeContext.Provider value={darkModeProviderValue}>
      {/* <AuthProvider> */}
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <Grid container direction="column" sx={{ minHeight: '100vh', minWidth: '300px' }}>
          <Grid item>
            <MainNavbar />
          </Grid>
          <Grid item flexGrow={1} sx={{ minHeight: '60vh' }}>
            {renderNav(pageContext?.layout)}
          </Grid>
          <Grid className="footer-test" item sx={{ bgcolor: 'primary.main', mt: 4, py: 3 }}>
            <Footer />
            <CookiesBar />
          </Grid>
        </Grid>
      </ThemeProvider>
      {/* </AuthProvider> */}
    </DarkModeContext.Provider>
  )
}

export default Layout

export const useDarkModeContext = () => {
  const ctx = useContext(DarkModeContext)
  if (!ctx) throw new Error('Out of StyleContext')
  return ctx
}
