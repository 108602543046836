export const getThemeObject = (mode, isDark, PRIMARY_MAIN) => ({
  palette: {
    mode,
    primary: { main: PRIMARY_MAIN },
    text: { secondary: mode === 'light' ? '#181818' : '#dddddd' },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          '&.Mui-selected': {
            color: isDark ? 'white' : 'primary',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {},
        indicator: { background: isDark ? 'white' : 'primary' },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      smm: 450,
      sm: 660,
      xsm: 750,
      md: 990,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '3rem',
      },
      '@media (min-width:900px)': {
        fontSize: '4rem',
      },
    },
    h2: {
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '2.25rem',
      },
      '@media (min-width:900px)': {
        fontSize: '3rem',
      },
    },
  },
})
