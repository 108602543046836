import React from 'react'
import { Avatar as MuiAvatar } from '@mui/material'

export const getAvatarContent = (user) => {
  if (user?.avatar_url) {
    return (
      <MuiAvatar
        src={user.avatar_url}
        alt={user.fullName || user.email}
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    )
  }
  if (user?.full_name) {
    return user.full_name
      .split(' ')
      .map((name) => name[0])
      .join('')
      .toUpperCase()
      .slice(0, 4)
  }
  if (user?.email) {
    return user.email.slice(0, 2).toUpperCase()
  }
  return 'U'
}
