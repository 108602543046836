import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { PRIMARY_MAIN } from './Layout'

const CookiesBar = () => (
  <CookieConsent
    // disableStyles
    location="bottom"
    buttonText="Zgoda"
    cookieName="cookiesConsent"
    style={{ background: '#2B373B', padding: '1rem', alignItems: 'center' }}
    buttonStyle={{
      color: 'inherit',
      fontSize: '1.1rem',
      background: PRIMARY_MAIN,
      padding: '0.7rem 1.1rem',
      borderRadius: '0.4rem',
    }}
    expires={150}
  >
    UWAGA! Ta strona korzysta z ciasteczek. Dalsze korzystanie ze strony oznacza, że zgadzasz się na
    ich użycie oraz akceptujesz politykę prywatności i politykę cookies
  </CookieConsent>
)

export default CookiesBar
