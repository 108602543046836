import { AppBar, Toolbar, useMediaQuery } from '@mui/material'
import React from 'react'
import NavbarLogo from './NavbarLogo'
import NavbarMenu from './NavbarMenu'
import NavbarMobileMenu from './NavbarMobileMenu'

const MainNavbar = () => {
  const matches = useMediaQuery('(min-width:1120px)')

  return (
    <>
      <AppBar sx={{ backgroundColor: 'inherit' }}>
        <Toolbar
          disableGutters
          sx={{
            bgcolor: 'primary.main',
            px: { xs: 3, xl: 10 },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <NavbarLogo />
          {matches ? <NavbarMenu /> : <NavbarMobileMenu />}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

export default MainNavbar
