import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
} from '@mui/material'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/authContext'
import DarkModeSwitch from '../DarkModeSwitch'
import { NAV_ITEMS } from '../Routes'
import { getAvatarContent } from '../../../helpers/getAvatarContent'

const NavbarMobileMenu = () => {
  const [open, setOpen] = useState(false)
  const [expandedItems, setExpandedItems] = useState({})
  const { user, signOut } = useAuth()

  const toggleDrawer = (isOpen) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpen(isOpen)
  }

  const handleExpandClick = (itemName) => {
    setExpandedItems((prev) => ({ ...prev, [itemName]: !prev[itemName] }))
  }

  const renderMenuItem = (item, depth = 0) => {
    const hasInnerItems = item.innerItems && item.innerItems.length > 0
    const isExpanded = expandedItems[item.name]

    return (
      <React.Fragment key={item.name}>
        <ListItem disablePadding>
          <ListItemButton
            component={item.path ? Link : 'div'}
            to={item.path}
            onClick={hasInnerItems ? () => handleExpandClick(item.name) : toggleDrawer(false)}
            sx={{ pl: 2 + depth * 2 }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
            {hasInnerItems && (isExpanded ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        </ListItem>
        {hasInnerItems && (
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <List component="div" dense>
              {item.innerItems.map((category) => (
                <React.Fragment key={category.name || category.items[0].path}>
                  {category.name && (
                    <ListItem disablePadding sx={{ pl: 4 + depth * 2 }}>
                      <ListItemText
                        primary={category.name}
                        primaryTypographyProps={{
                          variant: 'subtitle2',
                          fontWeight: 'bold',
                        }}
                      />
                    </ListItem>
                  )}
                  {category.items.map((innerItem) => renderMenuItem(innerItem, depth + 1))}
                </React.Fragment>
              ))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    )
  }

  const drawerContent = (
    <Box
      sx={{
        width: 280,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {user ? (
          <Box
            component={Link}
            to="/profil/"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
            }}
            onClick={toggleDrawer(false)}
          >
            <Avatar sx={{ mr: 2, bgcolor: 'primary.main' }}>{getAvatarContent(user)}</Avatar>
            <Typography variant="subtitle1">{user.full_name || user.email}</Typography>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/logowanie/"
            onClick={toggleDrawer(false)}
          >
            Zaloguj
          </Button>
        )}
        <DarkModeSwitch />
      </Box>
      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: (theme) => theme.palette.divider,
            borderRadius: '4px',
          },
        }}
      >
        <List>{NAV_ITEMS.map((item) => renderMenuItem(item))}</List>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        {user && (
          <Button
            fullWidth
            variant="outlined"
            color="inherit"
            onClick={() => {
              signOut()
              setOpen(false)
            }}
            startIcon={<LogoutIcon />}
          >
            Wyloguj
          </Button>
        )}
      </Box>
    </Box>
  )

  return (
    <>
      <IconButton onClick={() => setOpen(true)} color="inherit" aria-label="menu">
        <MenuIcon fontSize="large" />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        PaperProps={{
          sx: {
            width: 280,
          },
        }}
      >
        {drawerContent}
      </SwipeableDrawer>
    </>
  )
}

export default NavbarMobileMenu
