import React from 'react'
import { Container, Grid, Paper, Tab, Tabs, useMediaQuery } from '@mui/material'
import { Link } from 'gatsby'
import { useDarkModeContext } from '../Layout'
import { CollabNav } from '../../Ads/CollabNav'

const GRID_SPACING = 1
const BORDER_INDICATOR = '2px solid rgba(0, 0, 0, 0.5)'

const SecondaryNavTabs = ({ children, routes }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { isDarkMode } = useDarkModeContext()
  const tabNavOrientation = matches ? 'vertical' : 'horizontal'
  const currentTab = routes.findIndex(
    (route) =>
      location.pathname.replaceAll(/[^a-zA-Z0-9]/g, '') === route.path.replaceAll(/[^a-zA-Z0-9]/g, '')
  )

  const getCurrentTabOrZero = () => (currentTab > -1 ? currentTab : 0)

  return (
    <Container sx={{ mt: 2, px: 1 }} maxWidth="md">
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <Tabs
            value={getCurrentTabOrZero()}
            sx={{
              '& .MuiTabs-flexContainer': {
                flexWrap: 'wrap',
              },
              '& .Mui-selected': {
                color: 'inherit',
                fontSize: '0.70rem',
                fontWeight: 'bold',
                borderBottom: !matches ? BORDER_INDICATOR : 'none',
                borderRight: matches ? BORDER_INDICATOR : 'none',
                borderColor: isDarkMode ? 'white' : 'black',
              },
              '.MuiTabs-indicator': {
                background: 'none',
              },
            }}
            centered
            component={Paper}
            orientation={tabNavOrientation}
            variant={tabNavOrientation === 'vertical' ? 'standard' : 'fullWidth'}
          >
            {routes.map(({ path, name }) => (
              <Tab
                sx={{
                  fontSize: '0.70rem',
                  px: '1rem',
                  display: 'flex',
                  minWidth: '100px',
                }}
                key={path}
                to={path}
                label={name}
                component={Link}
              />
            ))}
          </Tabs>
          {matches && <CollabNav />}
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container spacing={GRID_SPACING}>
            <Grid item xs={12}>
              {children}
              {!matches && <CollabNav />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SecondaryNavTabs
