import React from 'react'
import { Button } from '@mui/material'
import { Link as Glink } from 'gatsby'
import ViewModuleIcon from '@mui/icons-material/ViewModule'

export const CollabNav = ({ mediumMt = 4 }) => (
  <Button
    component={Glink}
    to="/wszystkie-funkcje"
    variant="contained"
    color="primary"
    startIcon={<ViewModuleIcon />}
    sx={{ mt: { xs: 2, md: mediumMt }, mb: { xs: 1, md: 2 } }}
  >
    Wszystkie funkcje
  </Button>
)
